.App {
  
  text-align: center;
  min-width: 320px;
  position: relative;
  min-height: 100svh;
  vertical-align: center;
  padding-bottom: 50px;
}


.search-bar {
  padding-top: 200px;
  max-width: 600px;
  margin-inline: auto;
  padding: 20px 20px 0 0;

}

.search-bar input:focus {
  .search-bar {
  padding-top: 0px;
}
}

.country-block {
  text-align: left;
  padding: 20px;
  max-width: 900px;
  margin: auto;
}

ul {
  text-align: left;
  list-style-type: none;
  margin-left: -10%;
  margin-top: 0;
}

.App img.flag {
  max-width: 100%;
  border: 1px solid;
}

img.weather-icon {
  margin: -20px 10px 0 0;
  max-width: 100%;
}

.air-icon {
  margin-right: 10px;
}

.background {
  opacity: 0.03;
  transform: scale(2);
  top: 0;
  transform-origin: top;
  min-height: 100%;
  aspect-ratio: 1.4/1;

  mask-repeat: inherit;
  /* max-width: 100%; */
  max-height: 120%;
  border: 2px solid;
  position: fixed;

}

.caption {
  display: block;
  padding-bottom: 10px;
}

footer {
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  display: space-around;
  font-size: small;
  bottom: 0;
  position: absolute;
  padding: 10px;
  width: 100%;
  text-align: center;
}

footer a {
  color: gray
}

footer p {
  margin: 0 5px;
}
